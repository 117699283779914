<template>
  <div class="page">
    <head-nav />
    <div
      class="inner-ban"
      :style="{ backgroundImage: 'url(' + banner + ')' }"
    ></div>
    <div class="main">
      <div class="auto wrap flexLr">
        <lefter :bg="menuBackground" @click="awardFun" />
        <!-- <div class="main-l">
          <div
            class="menu-titbox"
            :style="{ backgroundImage: 'url(' + menuBackground + ')' }"
          >
            荣誉奖励
          </div>
          <ul class="menu-list">
            <li
              :class="awardIndex == item.typeCodeNum ? 'curBg' : ''"
              v-for="(item, index) in awardLists"
              :key="index"
              @click="awardFun(item)"
            >
              {{ item.names }}
            </li>
          </ul>
        </div> -->
        <div class="main-r flex-1 bg-white">
          <div class="conbx" v-show="!dialogTableVisible">
            <div class="inner-tt">
              <span class="bt">{{ awardTitles }}</span>
            </div>
            <div class="main-wrap">
              <ul class="personal-list flexUl flexLr">
                <li
                  v-for="(item, index) in awardDetailsList"
                  :key="index"
                  class="item"
                >
                  {{ item.Attach.PicPath }}
                  <div class="elImageBox">
                    <el-image
                      @mousedown="maxImgFun(item)"
                      class="elImage"
                      :src="item.imgList[0]"
                      :preview-src-list="item.imgList"
                    />
                  </div>
                  <div class="info" @click="awardDetailsFun(item)">
                    <h6 class="name ellipsis">{{ item.SF10 }}</h6>
                    <div class="txt clamp">{{ item.ItemName }}</div>
                    <div class="fz ellipsis">
                      <img
                        src="../../assets/images/reward_02.png"
                        class="ico"
                      />{{ item.SF49 }}
                    </div>
                    <div class="fz ellipsis">
                      <img
                        src="../../assets/images/reward_03.png"
                        class="ico"
                      />{{ item.SF1 }}
                    </div>
                  </div>
                </li>
              </ul>
              <div class="pagelist" v-show="dataCount > 10">
                <el-pagination
                  @size-change="handleSizeChange"
                  @current-change="handleCurrentChange"
                  :current-page="currentPage"
                  :page-sizes="[10, 15, 20, 25]"
                  :page-size="pageSize"
                  :total="dataCount"
                  layout="prev, pager, next"
                >
                </el-pagination>
              </div>
            </div>
          </div>
          <div class="popbx" v-if="dialogTableVisible">
            <el-dialog
              :title="awardTitle"
              :visible.sync="dialogTableVisible"
              :modal="false"
              class="new-dialog"
            >
              <detail ref="sonV" type="1"/>
            </el-dialog>
          </div>
        </div>
      </div>
    </div>
    <foot />
  </div>
</template>
<script>
import headNav from "@/components/head.vue";
import foot from "@/components/foot.vue";
import detail from "@/components/detail.vue";
import Vue from "vue";
import Lefter from "@/components/lefter.vue";
export default {
  name: "",
  data() {
    return {
      banner: require("@/assets/images/reward_01.jpg"),
      menuBackground: require("@/assets/images/navBg03.jpg"),
      //无奖励图片默认显示图片
      tolerantImg: require("@/assets/images/jiangbei.png"),
      awardIndex: "CX012",
      awardLists: [
        {
          names: "人才奖励",
          rootIdNum: "10000",
          itemIdNum: "23",
          typeCodeNum: "CX012",
        },
        {
          names: "科技奖励",
          rootIdNum: "10000",
          itemIdNum: "24",
          typeCodeNum: "CX013",
        },
        {
          names: "工程奖励",
          rootIdNum: "10000",
          itemIdNum: "40",
          typeCodeNum: "CX040",
        },
      ],
      // 标题
      awardTitle: "",
      awardTitles: "",
      rootIdNum: "",
      itemIdNum: "",
      typeCodeNum: "",
      awardDetailsList: [],
      dialogTableVisible: false,
      //默认第一页
      currentPage: 1,
      //数据总条数
      dataCount: 0,
      pageSize: 10,
      srcList: [],
    };
  },
  components: {
    headNav,
    foot,
    detail,
    Lefter,
  },
  watch: {
    $route(to, from) {
      if (this.$route.query.typeCode) {
        var typeCode = this.$route.query.typeCode;
        for (var index in this.awardLists) {
          var item = this.awardLists[index];
          if (item.typeCodeNum == typeCode) {
            this.awardFun(item);
          }
          if (item.typeCodeNum == typeCode && this.$route.query.supItemId) {
            this.UrlDetailRedirect();
          }
        }
      } else {
        var item = this.awardLists[0];
        this.awardFun(item);
      }
    },
  },
  mounted() {
    let that = this;
    that.rootIdNum = that.awardLists[0].rootIdNum;
    that.itemIdNum = that.awardLists[0].itemIdNum;
    that.typeCodeNum = that.awardLists[0].typeCodeNum;
    // 初始化加载奖励列表
    that.send(
      that.rootIdNum,
      that.itemIdNum,
      that.typeCodeNum,
      that.currentPage
    );
    that.awardTitles = that.awardLists[0].names;
    that.UrlDetailRedirect();
  },
  methods: {
    maxImgFun(item) {
      let that = this;
      that.srcList = [];
      let maxImg =
        that.apiUrl +
        "/Item/PreviewFile?fileName=" +
        encodeURIComponent(item.Thum[0]);
      console.log(maxImg, "maxImg");
      that.srcList.push(maxImg);
    },
    awardFun(item) {
      let that = this;
      that.dialogTableVisible = false;
      that.awardIndex = item.typeCodeNum;
      that.awardTitles = item.names;
      that.rootIdNum = item.rootIdNum;
      that.itemIdNum = item.itemIdNum;
      that.typeCodeNum = item.typeCodeNum;
      that.send(that.rootIdNum, that.itemIdNum, that.typeCodeNum);
      document.documentElement.scrollTop = document.body.scrollTop = 0;
    },
    // 加载奖励列表
    send(rootIdNum, itemIdNum, typeCodeNum, currentPage) {
      let that = this;
      if (rootIdNum == "" && itemIdNum == "" && typeCodeNum == "") {
        rootIdNum = that.rootIdNum;
        itemIdNum = that.itemIdNum;
        typeCodeNum = that.typeCodeNum;
      }
      var obj = {
        rootId: rootIdNum,
        itemId: itemIdNum,
        typeCode: typeCodeNum,
        pageIndex: currentPage,
        pageSize: that.pageSize,
        itemOrdey: true,
        DisableItemContent: true,
      };
      //   var obj = { rootId: '10000', itemId: '120', typeCode: 'CX032' };
      var centent = JSON.stringify(obj);
      $.ajax({
        type: "post",
        contentType: "application/json",
        url: this.apiUrl + "/Api/Source/Item",
        data: centent,
        success: function (res) {
          var datas = res.Data;
          that.awardDetailsList = datas.Items.map((item, index) => {
            item.imgList =
              item.Attach.length > 0
                ? item.Attach.map((i, index) => {
                    return i.PicPath;
                  })
                : [];
            console.log(item);
            return item;
          });
          that.dataCount = res.Count;
        },
      });
    },
    handleSizeChange(val) {
      let that = this;
      that.pageSize = val;
      //console.log(`每页 ${val} 条`);
    },
    handleCurrentChange(val) {
      let that = this;
      //console.log(`当前页: ${val}`);
      that.send("", "", "", val);
    },
    // 奖励详情
    awardDetailsFun(item) {
      let that = this;
      let detailsarr = "";
      var obj = {
        rootId: item.RootId,
        itemId: item.ItemId,
        typeCode: item.TypeCode,
        supItemIdNum: item.ParentId,
        userId: this.userToken,
      };

      var centent = JSON.stringify(obj);

      $.ajax({
        type: "post",
        contentType: "application/json",

        url: this.apiUrl + "/Api/ItemApi/EditDetail",
        data: centent,
        success: function (res) {
          detailsarr = res.Data.Attr;
          //console.log(detailsarr+'fffffffffff')
          that.$refs.sonV.childMethods(res);
          for (var i = 0; i < detailsarr.length; i++) {
            if (detailsarr[i].Source == "ItemName") {
              that.awardTitle = detailsarr[i].Value;
            }
          }
        },
      });
      document.documentElement.scrollTop = document.body.scrollTop = 0;
      this.dialogTableVisible = true;
    },
    //地址详情页跳转
    UrlDetailRedirect() {
      let that = this;
      let rootIdNum = 10000;
      let itemIdNum = this.$route.query.itemId;
      let typeCodeNum = this.$route.query.typeCode;
      let supItemIdNum = this.$route.query.supItemId;
      let names = this.$route.query.name;
      if (!(itemIdNum > 0)) return;
      var obj = {
        RootId: rootIdNum,
        ItemId: itemIdNum,
        TypeCode: typeCodeNum,
        ParentId: supItemIdNum,
      };
      that.awardIndex = typeCodeNum;
      if (names != "" && names != undefined) {
        setTimeout(function () {
          that.awardTitle = names;
          that.send(rootIdNum, itemIdNum, typeCodeNum);
        }, 0);
        return;
      }
      that.awardDetailsFun(obj);
    },
  },
};
</script>
<style>
.viewer-toolbar li:nth-child(5),
.viewer-toolbar li:nth-child(7) {
  display: none;
}
.personal-list,
.zoomimg {
  cursor: pointer;
}
.item .elImage {
  width: 160px;
  height: 200px;
}
.item .elImageBox {
  width: 160px;
  height: 200px;
  overflow: hidden;
}
.item :hover.elImage {
  width: 180px;
  height: 220px;
  margin-top: -10px;
  margin-left: -10px;
  transition: all 0.2s;
}
</style>
