<template>
  <div class="main-l">
    <div class="menu-titbox" :style="{ backgroundImage: 'url(' + bg + ')' }">
      {{ this.title }}
    </div>
    <ul class="menu-list">
      <li
        :class="achievementIndex === item.typeCodeNum ? 'curBg' : ''"
        v-for="(item, index) in menuData"
        :key="index"
        @click="achievementFun(item)"
      >
        {{ item.names }}
      </li>
    </ul>
  </div>
</template>
<script>
import { eventBus } from "@/utils/eventbus";
export default {
  name: "lefter",
  props: {
    bg: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      title: null,
      achievementIndex: "",
      menuBackground: "",
      menuData: [],
    };
  },
  components: {},
  created() {
    this.$nextTick(() => {});
    // console.log(this.routeName, "this.routeName");
    // console.log(this.$route, "this.$route");
    eventBus.on("navs", (val) => {
      //   console.log(val, "val");
      this.title = val.name;
      this.menuData = val.lists;
      this.achievementIndex = this.menuData[0].typeCodeNum;
    });
  },
  watch: {
    $route(to, from) {
      if (this.$route.query.typeCode) {
        // console.log('2121212');
        // this.menuData = this.navs.find((obj) => obj.name === this.$route.name).lists;
        // this.achievementIndex = this.menuData[0].typeCodeNum;
      }
    },
  },
  methods: {
    achievementFun(item) {
      this.achievementIndex = item.typeCodeNum;
      item.rootIdNum = "10000";
      this.$emit("click", item);
      //   console.log(1111, item);
    },
  },
};
</script>
