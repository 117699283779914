<template>
  <div class="detail">
    <div class="detailsBox">
      <div class="timebx">发布时间：{{ publishTime }}</div>
      <hr class="hr" />
      <div class="headline">基础信息</div>
      <el-row>
        <el-col :span="type == '1' ? 14 : 24">
          <ul class="tableBox">
            <li
              class="detailsMessage flexTc"
              v-for="(item, index) in detailList"
              :key="index"
              v-show="
                item.Value != '' &&
                item.Source != 'ItemName' &&
                item.Name != '创建日期' &&
                item.Name != '创建人员'
              "
            >
              <div class="titles">{{ item.Name }}:</div>
              <div class="centent">{{ item.Value }}</div>
            </li>
          </ul>
          <div style="height: 10px"></div>
          <div class="innerbx" v-if="textContent" v-html="textContent"></div>
          <hr class="hr" v-if="textContent" />
        </el-col>
        <el-col :span="10" v-if="type == '1'">
          <div class="imgbx flexC">
            <el-image
              v-if="imgsList.length > 0"
              :src="imgsList[0]"
              :preview-src-list="imgsList"
            >
              <div slot="error" class="image-slot">
               
              </div>
            </el-image>
          </div>
        </el-col>
      </el-row>
      <div class="affixbx" v-if="type !== '1'">
        <div class="headline" v-if="detailAffix.length > 0">附件</div>
        <div v-if="detailAffix.length > 0">
          <div class="affix">
            <table>
              <thead>
                <tr>
                  <th style="text-align: left">文件名</th>
                  <!-- <th >操作</th> -->
                </tr>
              </thead>
              <tbody>
                <tr v-for="(item, index) in detailAffix" :key="index">
                  <td style="width: 90%" :title="item.ItemName">
                    {{ item.ItemName }}
                  </td>
                  <!-- <td style="text-align: center; white-space: nowrap; "> -->
                  <!-- <div v-if="item.FileSize > 1048576">
                    文件超过1M，不支持预览下载 {{parseFloat(item.FileSize/1048576).toFixed(2) }}M
                  </div> -->
                  <!-- <div> -->
                  <!-- <a :href="apiUrl +'/Item/PreviewFile?fileName=' + encodeURIComponent(item.Source) " target="_blank">
                    <i class="iconfont yt-yulan"></i>预览</a> -->
                  <!-- <a :href="apiUrl+'/YSPT/Default/ResponseDownLoad?fileName=' +
                        encodeURIComponent(item.Source)">
                      <i class="iconfont yt-xiazai"></i>下载  
                      {{formatbytes(item.FileSize) }} 
                    </a> -->
                  <!-- <button @click="filePreview(item)">预览</button> -->
                  <!-- <el-progress v-if="index == progressID" type="line" :percentage="percentage"></el-progress> -->
                  <!-- </div> -->
                  <!-- </td> -->
                </tr>
              </tbody>
            </table>

            <iframe
              style="height: 100%; overflow: auto; width: 100%"
              frameborder="0"
              :src="fileSrc"
            ></iframe>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "detail",
  props: {
    type: {
      type: String,
      default: "0", //0.附件，1.图片
    },
  },
  data() {
    return {
      detailAffix: [],
      detailList: [],
      detailTime: "",
      detailLength: 0,
      percentage: 0,
      progressShow: false,
      publishTime: "",
      textContent: "",
      progressID: -1,
      fileSrc: "",
      tableau: "",
      imgUrl: "",
      imgsList: [],
    };
  },
  components: {},
  mounted() {},
  created() {},
  watch: {},
  methods: {
    childMethods(arrs) {
      let that = this;
      that.detailAffix = arrs.Data.Attach;
      that.detailList = arrs.Data.Attr;
      that.detailTime = that.detailList[0].SF1;
      that.textContent = arrs.Data.Content;
      // for (var i = 0; i < that.detailAffix.length; i++) {
      //   that.getUrls(that.detailAffix[i].Source);
      // }
      that.imgsList = that.detailAffix.map((item, index) => {
        return item.PicPath;
      });
      console.log(that.imgsList, "that.imgsList");
      //console.log(JSON.stringify(arrs.Data.Attr))
      for (var i = 0; i < that.detailList.length; i++) {
        if (that.detailList[i].Value == "") {
          that.detailLength += 1;
        } else if (that.detailList[i].Source == "CrDate") {
          that.publishTime = that.detailList[i].Value;
        }
        if (that.detailList[i].Type == "dic") {
          var DicList = that.detailList[i].DicList;
          DicList.forEach((element) => {
            if (element.Value == that.detailList[i].Value) {
              that.detailList[i].Value = element.Name;
            }
          });
        }
      }
    },
    download(url, title, token, index) {
      const x = new XMLHttpRequest();
      var that = this;
      that.progressID = index;
      x.open("GET", url, true);
      x.setRequestHeader("Authorization", token);
      x.responseType = "blob";
      x.onload = function () {
        const herf = window.URL.createObjectURL(x.response);
        const a = document.createElement("a");
        a.href = herf;
        a.download = title;
        a.click();
        if (navigator.msSaveBlob) {
          navigator.msSaveBlob(x.response, title);
        }
      };
      // todo: 添加下载进度
      x.onprogress = function (event) {
        // 只有 e.lengthComputable 为真，才会有进度条的信息

        if (event.lengthComputable) {
          var percentComplete = event.loaded / event.total;
          var progress = parseInt(percentComplete * 100);
          that.percentage = progress++;
          //console.log(progress);
        }
      };
      x.onreadystatechange = function () {
        if (x.readyState === 4) {
          // 4 = "loaded"
          if (x.status === 200) {
            // 200 = "OK"
            setTimeout(() => {
              that.progressID = -1;
              that.percentage = 0;
            }, 800);
          } else {
            alert("Problem retrieving data:" + x.statusText);
          }
        }
      };
      x.send();
    },
    formatbytes(bytes) {
      if (bytes === 0) return "0 B";
      var k = 1000, // or 1024
        sizes = ["B", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"],
        i = Math.floor(Math.log(bytes) / Math.log(k));

      return (bytes / Math.pow(k, i)).toPrecision(3) + " " + sizes[i];
    },
    filePreview(item) {
      this.$axios
        .get(
          this.apiUrl +
            "/YSPT/Default/ResponseDownLoad?fileName=" +
            encodeURIComponent(item.Source),
          {
            responseType: "blob",
          }
        )
        .then((res) => {
          var disposition = res.headers["content-disposition"];
          var filename = decodeURIComponent(disposition)
            .split("; ")[1]
            .replace("filename=", "");
          var blobType = "";

          for (var index in this.common.contentType) {
            var tmpType = this.common.contentType[index];
            var Ext = filename.substring(filename.lastIndexOf("."));
            if (tmpType.name == Ext) {
              blobType = tmpType.value;
            }
          }

          var blob = new Blob([res.data], {
            // 关键代码
            type: blobType,
          });

          const tmpUrl = window.URL.createObjectURL(blob);
          this.fileSrc = tmpUrl;
          // console.log(this.fileSrc)
          //渲染doc
          //docx.renderAsync(blob, this.$refs.docFile);
          //
          // let file = new FileReader();
          // file.readAsArrayBuffer(blob);
          // file.onload = ()=>{
          //   let workbook = XLSX.read(new Uint8Array(file.result), {type:"buffer"}); // 解析数据
          //   var worksheet = workbook.Sheets[workbook.SheetNames[0]]; // workbook.SheetNames 下存的是该文件每个工作表名字,这里取出第一个工作表
          //   this.tableau = XLSX.utils.sheet_to_html(worksheet); // 渲染
          // }
        });
    },
  },
};
</script>
<section class="teambx pdlrdf mrbdf">
    <div class="conbx bbxdf pddf mrbdf">
      <div class="title-main mrbdf"><span class="text-main">累计收益</span></div>

      <div class="totalbx  mrbdf">
        <ul class="flexLr tc">
          <li>
            <p class="col-gray">昨日指导价</p>
            <h3 class="fsxl pdtbsm col-blue">0</h3>
          </li>
          <li>
            <p class="col-gray">今日指导价</p>
            <h3 class="fsxl pdtbsm ">0</h3>
          </li>
          <li>
            <p class="col-gray">今日流通量</p>
            <h3 class="fsxl pdtbsm col-purple">0</h3>
          </li>
          <li>
            <p class="col-gray">今日涨幅</p>
            <h3 class="fsxl pdtbsm col-purple">0</h3>
          </li>
        </ul>
      </div>
  </section>
<style scoped>
.myprogress {
  position: relative;
  display: none;
}

.detail {
  margin: -31px -20px;
}

.detail .timebx {
  font-size: 14px;
  color: #999;
  padding: 0 20px;
  margin-bottom: 20px;
}

.detail .innerbx {
  line-height: 2.2;
  padding: 20px;
}

.hr {
  height: 4px;
  width: 100%;
  background-color: #f4f7fb;
  border: none;
  margin: 0;
}

.justify_content {
  display: flex;
  justify-content: center;
}

.flex_start {
  display: flex;
  justify-content: flex-start;
}

.tableBox {
  display: flex;
  flex-wrap: wrap;
}

.detailsBox {
  width: 100%;
  height: 100%;
  margin: 0 auto;
}

.detailsBox div {
  margin: 0;
}

.detailsBox .headTitle {
  font-weight: 600;
  font-size: 20px;
}

.detailsBox .headline {
  font-size: 14px;
  font-weight: 650;
  text-align: left;
  color: #000000;
  border-bottom: 2px solid #f4f7fb;
  padding: 10px 20px;
}

.detailsBox .tableBox {
  border-right: 1px solid #f4f7fb;
  border-bottom: 1px solid #f4f7fb;
}

.detailsBox .detailsMessage {
  flex-grow: 1;
  padding: 5px 15px;
  border-top: 1px solid #f4f7fb;
  border-left: 1px solid #f4f7fb;
  width: 100%;
  /* min-width: 50%; */
  /* height: 30px; */
  color: #000;
  line-height: 30px;
}

.detailsBox .detailsMessage:hover,
.detailsMessageBox .listShow:hover {
  background: rgba(244, 247, 251, 0.5);
}

.detailsMessage .titles {
  min-width: 120px;
  /* max-width: 200px; */
  padding-right: 10px;
  color: #999999;
}

.detailsMessageBox {
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: flex-start;
  justify-content: space-between;
  border-bottom: 1px solid #ecedee;
  border-right: 1px solid #ecedee;
  box-sizing: border-box;
}

.detailsMessageBox .listShow {
  width: 50%;
  background: #fbfbfb;
  cursor: pointer;
}

.listShow .detailList div {
  width: 50%;
  padding: 6px 0;
  border: 1px solid #ecedee;
  border-right: none;
  border-bottom: none;
  box-sizing: border-box;
  text-indent: 24px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  word-break: break-all;
}

.listShow .detailList div.titlebx {
  color: #999999;
}

.detailsBox .imgbx {
  padding: 20px;
}

.detailsBox .affix ul {
  display: flex;
  flex-wrap: wrap;
  border-bottom: 1px solid #e9e9e9;
  border-right: 1px solid #e9e9e9;
}

.detailsBox .affix li {
  flex-grow: 1;
  text-align: left;
  color: #409eff;
  border-top: 1px solid #e9e9e9;
  border-left: 1px solid #e9e9e9;
}

.detailsBox .affix thead th,
.detailsBox .affix tbody td {
  padding: 10px 20px;
  border-bottom: 1px solid #e9e9e9;
  border-right: 1px solid #e9e9e9;
}

.detailsBox .affix thead th:last-child,
.detailsBox .affix tbody td:last-child {
  border-right: none;
}

.detailsBox .affix tbody td {
  padding: 10px 20px;
}

.detailsBox .affix tbody td a {
  padding: 2px 15px;
  cursor: pointer;
}

.detailsBox .affix tbody td a:hover {
  background: #409eff;
  color: #fff;
}

.detailsBox .affix li a {
  display: block;
  padding: 8px 15px;
}

.detailsBox .affix a:hover {
  background: #409eff;
  color: #fff;
}

.navList .lineHint,
.navList .navListBox {
  display: none !important;
}
</style>
