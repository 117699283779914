var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"detail"},[_c('div',{staticClass:"detailsBox"},[_c('div',{staticClass:"timebx"},[_vm._v("发布时间："+_vm._s(_vm.publishTime))]),_c('hr',{staticClass:"hr"}),_c('div',{staticClass:"headline"},[_vm._v("基础信息")]),_c('el-row',[_c('el-col',{attrs:{"span":_vm.type == '1' ? 14 : 24}},[_c('ul',{staticClass:"tableBox"},_vm._l((_vm.detailList),function(item,index){return _c('li',{directives:[{name:"show",rawName:"v-show",value:(
              item.Value != '' &&
              item.Source != 'ItemName' &&
              item.Name != '创建日期' &&
              item.Name != '创建人员'
            ),expression:"\n              item.Value != '' &&\n              item.Source != 'ItemName' &&\n              item.Name != '创建日期' &&\n              item.Name != '创建人员'\n            "}],key:index,staticClass:"detailsMessage flexTc"},[_c('div',{staticClass:"titles"},[_vm._v(_vm._s(item.Name)+":")]),_c('div',{staticClass:"centent"},[_vm._v(_vm._s(item.Value))])])}),0),_c('div',{staticStyle:{"height":"10px"}}),(_vm.textContent)?_c('div',{staticClass:"innerbx",domProps:{"innerHTML":_vm._s(_vm.textContent)}}):_vm._e(),(_vm.textContent)?_c('hr',{staticClass:"hr"}):_vm._e()]),(_vm.type == '1')?_c('el-col',{attrs:{"span":10}},[_c('div',{staticClass:"imgbx flexC"},[(_vm.imgsList.length > 0)?_c('el-image',{attrs:{"src":_vm.imgsList[0],"preview-src-list":_vm.imgsList}},[_c('div',{staticClass:"image-slot",attrs:{"slot":"error"},slot:"error"})]):_vm._e()],1)]):_vm._e()],1),(_vm.type !== '1')?_c('div',{staticClass:"affixbx"},[(_vm.detailAffix.length > 0)?_c('div',{staticClass:"headline"},[_vm._v("附件")]):_vm._e(),(_vm.detailAffix.length > 0)?_c('div',[_c('div',{staticClass:"affix"},[_c('table',[_vm._m(0),_c('tbody',_vm._l((_vm.detailAffix),function(item,index){return _c('tr',{key:index},[_c('td',{staticStyle:{"width":"90%"},attrs:{"title":item.ItemName}},[_vm._v(" "+_vm._s(item.ItemName)+" ")])])}),0)]),_c('iframe',{staticStyle:{"height":"100%","overflow":"auto","width":"100%"},attrs:{"frameborder":"0","src":_vm.fileSrc}})])]):_vm._e()]):_vm._e()],1)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('thead',[_c('tr',[_c('th',{staticStyle:{"text-align":"left"}},[_vm._v("文件名")])])])
}]

export { render, staticRenderFns }